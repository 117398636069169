.opacity-animation {
  animation: opacityAnimation 3s linear;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 0;
    transform: translate(500px);
  }

  100% {
    opacity: 1;
    transform: translate(0px);
  }
}