.close-animation {
  animation: close linear 0.5s;
  width: 100%;
  background: #111;
  height: 112px;
}

.close-animation p {
  display: none;
}

.close-animation h1, .close-animation h2 {
  @apply text-white;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


@keyframes close {
  0% {
    height: 200px;
    background: white;
  }

  50% {
    height: 120px;
  }

  100% {
    height: 112px;
    background: #111;
  }
}

.open-animation {
  animation: open linear 1s;
  width: 100%;
  background: white;
  padding-top: 48px;
  justify-content: space-between;
}

.open-animation p {
  display: block;
}

.open-animation h1, .open-animation h2 {
  @apply text-black font-[Thunder];
}

.open-animation h1 {
  @apply font-[ThunderBold] font-semibold;
}

@keyframes open {
  0% {
    height: 112px;
    background: #111;
    padding-top: 80px;
    max-width: 100%;
  }

  100% {
    height: 509px;
    background: white;
    padding-top: 48px;
  }
}

.hover-card:hover svg path {
  stroke: #ffd10a
}

.close-animation h1 {
  animation: moveToTop 0.5s linear;
}

@keyframes moveToTop {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0px);
  }
}
.progressbar-animation {
  animation: progressDisplay 1s linear;
}

@keyframes progressDisplay {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}