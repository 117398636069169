.close-animation {
  animation: close linear 1s;
  width: 176px;
  background: #111;
  padding-top: 80px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: inherit;
  cursor: pointer;
}

.close-animation p {
  display: none;
}

.close-animation h1, .close-animation h2 {
  @apply text-white;
  transform: translate(-34%, 80px) rotate(-90deg);
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.close-animation:first-child h1, .close-animation:first-child h2 {
  transform: translate(-34%, 120px) rotate(-90deg);
}

@keyframes close {
  0% {
    width: 390px;
    background: white;
    padding-top: 48px;
  }

  100% {
    width: 176px;
    background: #111;
    padding-top: 80px;
    max-width: 100%;
  }
}

@keyframes closeTablet {
  0% {
    width: 319px;
    background: white;
    padding-top: 48px;
  }

  100% {
    width: 112px;
    background: #111;
    padding-top: 80px;
    max-width: 100%;
  }
}

.open-animation {
  animation: open linear 1s;
  width: 390px;
  background: white;
  padding-top: 48px;
  max-width: 100%;
  justify-content: space-between;
  text-align: start
}

.open-animation p {
  display: block;
  overflow: hidden;
  width: 310px;
  max-width: 310px;
  overflow: hidden
}

.open-animation h1, .open-animation h2 {
  @apply text-black font-[Thunder];
  transform: translate(0px, 0px) rotate(0deg);
}

.open-animation h1 {
  @apply font-[ThunderBold];
}

@keyframes open {
  0% {
    width: 176px;
    background: #111;
    padding-top: 80px;
    max-width: 100%;
  }

  100% {
    width: 390px;
    background: white;
    padding-top: 48px;
  }
}

@keyframes openTablet {
  0% {
    width: 112px;
    background: #111;
    padding-top: 80px;
    max-width: 100%;
  }

  100% {
    width: 319px;
    background: white;
    padding-top: 48px;
  }
}

.hover-card:hover svg path {
  stroke: #ffd10a
}

@media screen and (max-width: 768px) {
  .close-animation {
    width: 112px;
    animation: closeTablet linear 1s;
  }

  .open-animation {
    width: 319px;
    animation: openTablet linear 1s;
  }
}