.box {
  @apply h-[9px] w-[9px] border-solid border-2 border-[#B0B0B0] rounded-[2px]
}

.active-box {
  @apply h-[9px] w-[9px] bg-primary rounded-[2px] 
}

.menu-item {
  @apply ml-3 uppercase text-[#B0B0B0] text-[10px] font-light leading-[16px] font-[BeVietnam];
}

.active-menu-item {
  @apply ml-3 uppercase text-white text-[10px] font-light leading-[16px] font-[BeVietnam];
}

@keyframes expand {
  0% {
    scale: 0;
    transform: translateY(-20px);
    height: 0;
  }

  100% {
    scale: 1;
    transform: translateY(0px);
    height: 40px;
  }
}

.fill-animation {
  animation: expand 0.5s linear;
  height: 40px;
  margin-bottom: 8px;
}

@keyframes empty {
  0% {
    scale: 1;
    transform: translateY(0px);
    height: 40px;
  }

  100% {
    scale: 0;
    transform: translateY(-20px);
    height: 0;
    display: none;
  }
}

.empty-animation {
  animation: empty 0.5s linear;
}