.column-fit {
  column-count: 2;
  column-fill: auto;
  column-gap: 56px;
  overflow-y: scroll;
  height: 75%;
}

.close-animation {
  animation: opacityAnimation 0.1s linear;
  position: unset;
}

@keyframes opacityAnimation {
  from {
    opacity: 1;
    display: block;
    position: fixed;
  }

  to {
    opacity: 0;
    display: none;
    position: unset;
  }
}

@media screen and (max-width: 1024px) {
  .column-fit {
    column-count: 1;
    column-fill: auto;
    column-gap: 0;
    height: fit-content;
  }

  .shadow {
    background: linear-gradient(179.33deg, rgba(255, 252, 252, 0) 0.58%, #FFFFFF 80.75%);
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 135px;
    z-index: 30;
  }
}