.animation path {
  animation: fillShape 2s linear infinite;
  stroke-dasharray: 800;
  stroke-dashoffset: -800;
}

@keyframes fillShape {
  0% {
    stroke: #b0b0b0;
    stroke-dashoffset: -800;

  }

  100% {
    stroke: #ffd10a;
    stroke-dashoffset: 10;
  }
}

.animation-safari path {
  animation: fillShapeSafari 2s linear infinite;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
}

.animation-safari {
  transform: rotate(180deg)
}

@keyframes fillShapeSafari {
  0% {
    stroke: #b0b0b0;
    stroke-dashoffset: 800;

  }

  100% {
    stroke: #ffd10a;
    stroke-dashoffset: 10;
  }
}

.float:hover {
  animation: float 2s infinite;
}

@keyframes float {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-10px)
    
  }

  100% {
    transform: translateY(0)
  }
}

@media screen and (max-width: 1024px) {
  .float:hover {
    animation: unset;
  }
}