.button-animation {
  animation: buttonAnimation 0.5s linear;
  transform: rotate(-90deg);
}

.button-animation path {
  animation: fillAnimation 0.5s linear;
  stroke: #ffd10a;
}

@keyframes buttonAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-90deg);
    z-index: 30;
  }
}

@keyframes fillAnimation {
  0% {
    stroke: white;
  }

  100% {
    stroke: #ffd10a;
  }
}

.reverse-button-animation {
  animation: reverseButtonAnimation 0.5s linear;
}

.reverse-button-animation path {
  animation: reverseFillAnimation 0.5s linear;
}

@keyframes reverseButtonAnimation {
  0% {
    transform: rotate(-90deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes reverseFillAnimation {
  0% {
    stroke: #ffd10a;
  }

  100% {
    stroke: white;
  }
}

.display-nav {
  @apply z-20 top-0 w-full h-full bg-black overflow-scroll block;
  animation: translateAnimation 0.5s linear;
  transform: translate(0);
}

@keyframes translateAnimation {
  0% {
    transform: translate(100%);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes translateBackAnimation {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(200%);
  }
}

.box {
  @apply h-[16px] w-[16px] border-solid border-2 border-[#B0B0B0] rounded-[2px]
}

.active-box {
  @apply h-[16px] w-[16px] bg-primary rounded-[2px] 
}

.menu-item {
  @apply ml-3 uppercase text-[#B0B0B0];
}

.active-menu-item {
  @apply ml-3 uppercase text-white;
}

.nav {
  background: black;
  width: 100%;
  z-index: 30;
  transform: translate(250%);
  animation: translateBackAnimation 0.5s linear;
}

.shade {
  @apply w-full h-[250px] bottom-0 sticky;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0.00) 0%, rgba(17, 17, 17, 0.90) 100%);
}