.fill-yellow:hover svg path {
  stroke: #ffd10a;
}

@media screen and (max-width: 768px) {
  .adjust-color path  {
    stroke: #ffd10a !important;
  }

  .fill-yellow:hover svg path {
    stroke: white;
  }
}