.arrows-container {
  margin-bottom: 40px;
  animation: float 1.5s infinite;
}

.arrows-container svg:first-child path {
  animation: turnYellow 1.5s infinite;
}

.arrows-container svg:nth-child(2) path {
  animation: turnYellow1 1.5s infinite;
}

.arrows-container svg:nth-child(3) path {
  animation: turnYellow2 1.5s infinite;
}

.arrows-container svg {
  margin-top: 3px;
}

@keyframes turnYellow {
  0% {
    stroke: white;
  }

  25% {
    stroke: #ffd10a;
  }
}

@keyframes turnYellow1 {
  0% {
    stroke: white;
  }

  50% {
    stroke: #ffd10a;
  }
}

@keyframes turnYellow2 {
  0% {
    stroke: white;
  }

  75% {
    stroke: #ffd10a;
  }
}

@keyframes float {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-10px)
    
  }

  100% {
    transform: translateY(0)
  }
}