.hover-effect span:first-child  {
  background: linear-gradient(to right, #ffd10a 50%, #111 50%);
  background-size: 200% 100%;
  background-position: bottom right;
  transition: all .5s ease-out;
  border: transparent;
}

.hover-effect:hover span:first-child  {
  background-position: bottom left;
  color: #111;
}