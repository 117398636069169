.left-animation img {
  animation: shakeAnimation 1.5s ease-in-out, zoomAnimation 2s linear, zoomAnimation 2s linear;
}

.top-animation img {
  animation: shakeAnimation 1.5s ease-in-out, zoomAnimation 2s linear , zoomAnimation 2s linear 0.5s;
}

.bottom-animation img {
  animation: shakeAnimation 1.5s ease-in-out, zoomAnimation 2s linear , zoomAnimation 2s linear 1s;
}

.right-animation img {
  animation: shakeAnimation 1.5s ease-in-out, maxZoomAnimation 2s linear , maxZoomAnimation 2s linear 1.5s;
}

@keyframes zoomAnimation {
  0% {
    scale: 1;
    transform: translate(0px, 0px)
  }

  15% {
    scale: 1.3;
    transform: translate(90%, 0px)
  }

  30% {
    scale: 1.5;
    transform: translate(150%, 0px)
  }

  50% {
    scale: 2.3;
    transform: translate(250%, 0px)
  }

  75% {
    scale: 3.3;
    transform: translate(60%, 0px)
  }

  100% {
    scale: 4;
    transform: translate(-150%, 0px)
  }
}


@keyframes maxZoomAnimation {
  0% {
    scale: 1;
    transform: translate(0px, 0px)
  }

  15% {
    scale: 3;
    transform: translate(3vw, 0px)
  }

  30% {
    scale: 5;
    transform: translate(5vw, 0px)
  }

  50% {
    scale: 10;
    transform: translate(10vw, 0px)
  }

  75% {
    scale: 15;
    transform: translate(7vw, 0px)
  }

  100% {
    scale: 20;
    transform: translate(-50px, 0px)
  }
}


@keyframes shakeAnimation {
  0% { transform: rotate(0deg); }
  7% { transform: rotate(5deg); }
  14% { transform: rotate(0eg); }
  21% { transform: rotate(-5deg); }
  28% { transform: rotate(0deg); }
  35% { transform: rotate(5deg); }
  42% { transform: rotate(0eg); }
  49% { transform: rotate(-5deg); }
  56% { transform: rotate(5deg); }
  63% { transform: rotate(0eg); }
  70% { transform: rotate(-5deg); }
  77% { transform: rotate(5deg); }
  84% { transform: rotate(0eg); }
  91% { transform: rotate(-5deg); }
  97% { transform: rotate(0deg); }
}

@media screen and (max-width: 768px) {
  .container {
    display: none;
  }
}