.animation path {
  animation: fillShape 2s linear infinite;
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
}

@keyframes fillShape {
  0% {
    stroke: #B0B0B0;
    stroke-dashoffset: 1200;

  }

  100% {
    stroke: #ffd10a;
    stroke-dashoffset: 10;
  }
}

.float:hover {
  animation: float 2s infinite;
}

@keyframes float {
  0% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(-10px)
    
  }

  100% {
    transform: translateY(0)
  }
}

@media screen and (max-width: 1024px) {
  .float:hover {
    animation: unset;
  }
}